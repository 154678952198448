export const i18nList = {
  currencyConfiguration: "货币配置",
  fiatCurrencyConfiguration: "法定货币配置",
  administrativeAreaManagement: "行政区域管理",
  dictionaryManagement: "字典管理",
  languageConfiguration: "语言配置",
  systemData: "数据源列表",
  systemDefinition: "枚举数据源",
  dictionaryData: "枚举数据",
  port: "港口",
  region: "区域",
  shipRoute: "航线",
  exchangeRateConfig: "汇率配置",
  costItemConfig: "费用项配置",
  countryRegionConfigurationQuery: "国家或地区配置",
  countryRegionConfigurationDetail:"国家或地区配置详情",
  settleExchangeRateToBeMaintained: "结算汇率待维护",
  taxAccountExchangeRateMaintained: "税务记账汇率待维护",
  referExchangeRateToBeMaintained: "参考汇率待维护",
  serviceInformationDueForMaintain: "服务信息临期待维护"
}

// async function queryI18nSysListBatch(keys: string[]) {
//   const BATCH_SIZE = 1000;
//   const batches = [];

//   for (let i = 0; i < keys.length; i += BATCH_SIZE) {
//     batches.push(keys.slice(i, i + BATCH_SIZE));
//   }

//   const responses = await Promise.allSettled(batches.map(batch => queryI18nSysList(batch)));

//   return responses.reduce((acc, response) => {
//     if (response.status === "fulfilled") {
//       Object.assign(acc, response.value.data);
//     }
//     return acc;
//   }, {});
// }

// export async function loadLocaleMessages() {
//   try {
//     const i18nData = await queryI18nSysListBatch(Object.keys(i18nList));
//     window.$i18n = {
//       t: (key: string) => Object.assign(i18nList, i18nData)[key] || key;
//     }
//   } catch (err) {
//     console.error("Failed to load remote internationalization entries", err);
//   }
// }


// export async function initI18n() {
//   await loadLocaleMessages();
// }
